import { useEffect } from 'react';
import EmarsysTracker from '@vaimo/emarsys-tracking/src/helpers/emarsysTracker';

export const useCurrency = ({ currencyCode }) => {
    useEffect(() => {
        if (!currencyCode) return;

        EmarsysTracker.data({
            displayCurrency: currencyCode,
        });
    }, []);
};
