import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { mapAddToCartData } from '../utils';
import type { IProduct } from '../types';
import { ConversionEventTypesEnum, PixelEventTypesEnum } from '../types';

export const useAddToCart = (product: IProduct | null): void => {
    const { quantity, sku } = product || {};
    const eventId = useUuid([quantity, sku]);

    useConversionApiRequest({
        eventId,
        eventInputData: { quantity, sku },
        eventName: ConversionEventTypesEnum.ADD_TO_CART,
    });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.ADD_TO_CART,
        payload: mapAddToCartData(product),
    });
};
