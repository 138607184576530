import { useEmarsysContext } from '../context/emarsys';
import { useCurrency as useCurrencyHook } from '@vaimo/emarsys-tracking/src/hooks';

export const useCurrency = () => {
    const [{ currency }] = useEmarsysContext();

    useCurrencyHook({
        currencyCode: currency?.current_currency_code
            ? currency?.current_currency_code
            : currency?.default_display_currency_code,
    });
};
