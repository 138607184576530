import { Dispatch, ReactNode } from 'react';

export enum PixelEventTypesEnum {
    ADD_PAYMENT_INFO = 'AddPaymentInfo',
    ADD_TO_CART = 'AddToCart',
    ADD_TO_WISHLIST = 'AddToWishList',
    INITIATE_CHECKOUT = 'InitiateCheckout',
    LEAD = 'Lead',
    PAGE_TYPE_EVENT = 'Pagetypeevent',
    PAGE_VIEW = 'PageView',
    PRO_DISCOUNT = 'Pro discount',
    PRO_DISCOUNT_AMOUNT = 'Discount amount',
    PRO_SIGNUP_FORM = 'Pro sign form completed',
    PRO_TYPE = 'Pro type',
    PURCHASE = 'Purchase',
    VIEW_CATEGORY = 'ViewCategory',
    VIEW_CONTENT = 'ViewContent',
}

export enum ConversionEventTypesEnum {
    ADD_PAYMENT_INFO = 'AddPaymentInfo',
    ADD_TO_CART = 'AddToCart',
    ADD_TO_WISHLIST = 'AddToWishList',
    INITIATE_CHECKOUT = 'InitiateCheckout',
    LEAD = 'Lead',
    PAGE_TYPE_EVENT = 'PageType',
    PAGE_VIEW = 'PageView',
    PRO_DISCOUNT = 'ProDiscount',
    PRO_DISCOUNT_AMOUNT = 'ProDiscountAmount',
    PRO_SIGNUP_FORM = 'ProSignupCompleted',
    PRO_TYPE = 'ProType',
    PURCHASE = 'Purchase',
    VIEW_CATEGORY = 'ViewCategory',
    VIEW_CONTENT = 'ViewContent',
}

export enum ContentTypesEnum {
    PRODUCT = 'product',
}

export enum ContentNamesEnum {
    PURCHASE = 'Purchase',
}

export enum ContentCategoriesEnum {
    BEGIN_CHECKOUT = 'Checkout',
}

export enum PixelTrackTypesEnum {
    TRACK = 'track',
    TRACK_SINGLE = 'trackSingle',
    TRACK_SINGLE_CUSTOM = 'trackSingleCustom',
}

export type Money = {
    __typename: 'Money';
    currency: string;
    value: number;
};

export type StringOrStringArray = string | (string | undefined)[];
export type NumberOrStringArray = number | string[];

export interface ICartProductContents {
    id: string | undefined;
    item_price: number | undefined;
    quantity: number | undefined;
}

export interface ICartItems {
    items: {
        prices: {
            row_total: Money;
            row_total_including_tax: Money;
        };
        quantity: number;
        sku: string;
    }[];
}

export interface ICartItemsWithQuantity extends ICartItems {
    quantity: number;
}

export interface IContextInitState {
    facebookPixelData: {
        pixelId: string;
        source: string;
    };
    isPixelAllowDuplicatePageViews: boolean;
    isPixelEnabled: boolean;
    isPixelInitEnabled: boolean;
    isPixelPushStateDisabled: boolean;
    isTrackingEnabled: boolean;
    partytownFacebookEnabled: boolean;
    storeCode?: string;
}

export interface IContextUserState {
    lastViewedProduct: any;
    userProData: any;
}

export interface IContextProviderProps {
    actions: Record<any, any>;
    children: ReactNode;
    facebookState: IContextUserState;
    initState: IContextInitState;
}

interface IContextFullState extends IContextInitState, IContextUserState {}

export type TFacebookContext = [IContextFullState, Record<string, Dispatch<any>>];
