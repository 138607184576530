import { useEffect } from 'react';
import { cookieManager } from '@vaimo-int/one-trust';
import { useFitAnalytics } from './useFitAnalytics';
import { useScript } from './useScript';
import { getOrderConfirmationProductDataFromSku, getPriceWithOrWithoutTax } from '../utils/utils';
import type { useFitAnalyticsOrderConfirmationProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare module globalThis {
    let fitAnalyticsData: any;
}

export const useFitAnalyticsOrderConfirmation = ({
    currencyCode,
    isEnabled,
    isPriceIncludesTax,
    isSignedIn,
    items,
    orderNumber,
    scriptSrc,
    storeCode,
}: useFitAnalyticsOrderConfirmationProps): void => {
    const { SSID, currency, shopCountry, shopLanguage, userId } = useFitAnalytics({
        currencyCode,
        isSignedIn,
        storeCode,
    });

    useScript({ isEnabled, src: scriptSrc });

    useEffect(() => {
        if (!isEnabled || !scriptSrc) {
            return;
        }

        cookieManager.executeIfAllowed(() => {
            globalThis.fitAnalyticsData = {
                SSID,
                currency,
                orderId: `${orderNumber}`,
                products: items
                    .filter(({ __typename }) => __typename === 'ConfigurableCartItem')
                    .map(({ configured_variant, prices, quantity }) => ({
                        ...getOrderConfirmationProductDataFromSku(configured_variant?.sku),
                        price: `${getPriceWithOrWithoutTax(prices, quantity, isPriceIncludesTax)}`,
                        quantity: `${quantity}`,
                    })),
                shopCountry,
                shopLanguage,
                userId,
            };
        }, cookieManager.PrivacyGroupEnum.FUNCTIONAL);

        return () => {
            delete globalThis.fitAnalyticsData;
        };
    }, [
        SSID,
        currency,
        items,
        isEnabled,
        isPriceIncludesTax,
        scriptSrc,
        orderNumber,
        shopCountry,
        shopLanguage,
        userId,
    ]);
};
