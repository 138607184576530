import { useEffect, useCallback, useState } from 'react';
import { IBMAddToCart } from '@vaimo/bold-metrics/src/types';

export const useBMAddToCart = ({
    boldMetricsCallToAction,
    handleAddToCart,
    isBoldMetricsEnabled,
    setSelectedSize,
}: IBMAddToCart) => {
    const [sizeSelectedFlag, setSizeSelectedFlag] = useState(false);

    const handleBoldMetricsAddToCart = useCallback(
        (e: any) => {
            const { size } = e.detail;
            const productTileList = document.querySelector('[data-bm-type="product-tile-list"]');

            if (productTileList) {
                const availableSizeButtons = productTileList.querySelectorAll('[aria-disabled="false"]');
                const availableSizeButtonsArray = Array.from(availableSizeButtons);

                const selectedItem = availableSizeButtonsArray.find((item) => (item as HTMLElement).innerText == size);

                if (selectedItem) {
                    setSelectedSize(size);
                    (selectedItem as HTMLElement).click();
                    setSizeSelectedFlag(true);
                }
            }
        },
        [setSelectedSize],
    );

    useEffect(() => {
        if (!isBoldMetricsEnabled) return;

        document.addEventListener('bmi_add_to_cart', handleBoldMetricsAddToCart);

        return () => {
            document.removeEventListener('bmi_add_to_cart', handleBoldMetricsAddToCart);
        };
    }, [isBoldMetricsEnabled, handleBoldMetricsAddToCart]);

    useEffect(() => {
        if (sizeSelectedFlag && boldMetricsCallToAction === 'add_to_cart') {
            handleAddToCart({ quantity: 1 });
            setSizeSelectedFlag(false);
        }
    }, [sizeSelectedFlag, boldMetricsCallToAction, handleAddToCart]);

    return null;
};
