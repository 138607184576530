import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { mapNewsletterLeadData } from '../utils';
import { PixelEventTypesEnum, IEmail, ConversionEventTypesEnum } from '../types';

export const useNewsletterLead = ({ email }: IEmail): void => {
    const eventId = useUuid([email]);

    useConversionApiRequest({ eventId, eventInputData: { email }, eventName: ConversionEventTypesEnum.LEAD });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.LEAD,
        payload: mapNewsletterLeadData(),
    });
};
