'use client';

import React, { createContext, useContext, useMemo } from 'react';
import { connect } from 'react-redux';

import actions from '../store/actions/emarsys';
import bindActionCreators from './bindActionCreators';

const EmarsysContext = createContext();

const EmarsysContextProvider = (props) => {
    const { actions, children, emarsysState, initState } = props;

    const contextValue = useMemo(
        () => [
            { ...initState, ...emarsysState },
            {
                actions,
            },
        ],
        [actions, emarsysState, initState],
    );

    return <EmarsysContext.Provider value={contextValue}>{children}</EmarsysContext.Provider>;
};

const mapStateToProps = ({ emarsys }) => ({ emarsysState: emarsys });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmarsysContextProvider);

export const useEmarsysContext = () => useContext(EmarsysContext);
