import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUserContext } from '@/lib/context';
import { useUuid } from './useUuid';
import { useFacebookContext } from '../context/facebook';
import { mapProDiscountData } from '../utils';
import { ConversionEventTypesEnum, PixelEventTypesEnum } from '../types';

enum AccountTypesEnum {
    GENERAL = 'General',
    GUEST = 'NOT LOGGED IN',
    PRO = 'PRO Customers',
}

export const useProDiscount = (): void => {
    const [{ isSignedIn }] = useUserContext();
    const [{ userProData }] = useFacebookContext();
    const eventId = useUuid([userProData?.customer_id]);

    const userType = !isSignedIn
        ? AccountTypesEnum.GUEST
        : userProData?.pro_account?.discount_applicable
          ? AccountTypesEnum.PRO
          : AccountTypesEnum.GENERAL;

    useConversionApiRequest({
        eventId,
        eventInputData: { customerId: isSignedIn ? userProData?.customer_id : null },
        eventName: ConversionEventTypesEnum.PRO_DISCOUNT,
    });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.PRO_DISCOUNT,
        payload: mapProDiscountData({ userType }),
    });
};
