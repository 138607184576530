import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { useFacebookContext } from '../context/facebook';
import { mapProTypeData } from '../utils';
import { ConversionEventTypesEnum, PixelEventTypesEnum } from '../types';

export const useProType = ({ isSignedIn }: { isSignedIn?: boolean }): void => {
    const [{ userProData }] = useFacebookContext();
    const eventId = useUuid([userProData?.customer_id]);

    const isActiveProUser = isSignedIn && userProData?.pro_account?.discount_applicable;

    useConversionApiRequest({
        eventId,
        eventInputData: { customerId: isActiveProUser ? userProData?.customer_id : null },
        eventName: ConversionEventTypesEnum.PRO_TYPE,
    });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.PRO_TYPE,
        payload: mapProTypeData({
            isActiveProUser,
            proClassification: userProData?.pro_account?.classification?.title,
        }),
    });
};
