import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { mapViewCategoryData } from '../utils';
import { PixelEventTypesEnum, IViewCategory, ConversionEventTypesEnum } from '../types';

export const useViewCategory = ({ categoryName }: IViewCategory): void => {
    const eventId = useUuid([categoryName]);

    useConversionApiRequest({
        eventId,
        eventInputData: { categoryName },
        eventName: ConversionEventTypesEnum.VIEW_CATEGORY,
    });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.VIEW_CATEGORY,
        payload: mapViewCategoryData({ categoryName }),
    });
};
