import { useEffect } from 'react';
import type { ICart } from '@/types/cart';
interface IBoldMetrics {
    orderDetailsData: {
        cart: ICart;
    };
    orderNumber: string;
}
export const useBoldMetricsTransaction = ({ orderDetailsData, orderNumber }: IBoldMetrics) => {
    const { cart } = orderDetailsData;
    const { items, prices, shipping_addresses } = cart;
    const address = shipping_addresses ? shipping_addresses[0] : null;
    const sizeAttribute = items[0].product?.configurable_options?.find((e) => e.attribute_code === 'size');

    useEffect(() => {
        if (typeof globalThis?.bmiWriteTransaction === 'function') {
            if (address) {
                const purchases = items.map((item: any) => {
                    const sizeOptions = item.configurable_options?.find(
                        (option: any) => option.configurable_product_option_uid === sizeAttribute?.uid,
                    );

                    if (!sizeOptions) return;

                    return {
                        category: item.product.bm_category,
                        msrp: item.product.price_range.minimum_price.regular_price.value,
                        product_id: item.id,
                        product_name: item.product.name,
                        quantity: item.quantity,
                        sale_price: item.prices.final_price.value,
                        size: sizeOptions?.value_label,
                        variant_id: item.configured_variant.id,
                        variant_sku: item.configured_variant.sku,
                    };
                });

                const data = {
                    city: address.city,
                    client_id: 'hellyhansen',
                    country_code: address.country?.label,
                    currency: prices.grand_total.currency,
                    purchases,
                    shipping_price: address?.selected_shipping_method?.amount.value,
                    total_price: prices?.grand_total?.value,
                    transaction_id: orderNumber,
                    zip: address.postcode,
                };

                globalThis
                    .bmiWriteTransaction(data)
                    .then((res) => console.log(res))
                    .catch((err) => console.error(err));
            }
        } else {
            console.error('bmiWriteTransaction is not defined or not a function.');
        }
    }, []);

    return null;
};
