'use client';

import React, { createContext, Dispatch, useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import actions from '../store/actions/facebook';
import bindActionCreators from '../utils/bindActionCreators';
import { IContextProviderProps, IContextUserState, TFacebookContext } from '../types';

const FacebookContext = createContext<TFacebookContext>([{}, {}] as unknown as TFacebookContext);

const FacebookContextProvider = ({ actions, children, facebookState, initState }: IContextProviderProps) => {
    const contextValue = useMemo(
        () => [{ ...initState, ...facebookState }, actions] as TFacebookContext,
        [actions, facebookState, initState],
    );

    return <FacebookContext.Provider value={contextValue}>{children}</FacebookContext.Provider>;
};

const mapStateToProps = ({ facebook }: { facebook: IContextUserState }) => ({ facebookState: facebook });

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookContextProvider);

export const useFacebookContext = () => useContext(FacebookContext);
