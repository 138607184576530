import { useEffect } from 'react';
import { useEmarsysContext } from '../context/emarsys';
import EmarsysTracker from '@vaimo/emarsys-tracking/src/helpers/emarsysTracker';
import { getPriceWithOrWithoutTax } from '@vaimo/emarsys-tracking/src/helpers/utils';

export const useCart = ({ items }) => {
    const [{ isPriceIncludesTax }] = useEmarsysContext();

    useEffect(() => {
        if (!Array.isArray(items)) return;

        EmarsysTracker.data({
            cart: items.map((item) => ({
                item: item.configured_variant?.sku || item.product?.sku,
                price: getPriceWithOrWithoutTax(item, isPriceIncludesTax),
                quantity: item.quantity,
            })),
        });
    }, [isPriceIncludesTax]);
};
