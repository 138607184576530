import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import operations from './conversionApi.gql';
import type { IFacebookConversionEvent, IFacebookConversionEventResponse } from '../types';
import { useFacebookContext } from '../context/facebook';

export const useConversionApiRequest = ({ eventId, eventInputData, eventName }: IFacebookConversionEvent): void => {
    const { facebookConversionEventMutation } = operations;
    const [sendFacebookConversionEvent] = useMutation<IFacebookConversionEventResponse>(
        facebookConversionEventMutation,
    );

    const [{ isTrackingEnabled }] = useFacebookContext();

    useEffect(() => {
        if (
            !isTrackingEnabled ||
            !eventId ||
            !eventName ||
            !eventInputData ||
            Object.values(eventInputData).every((value) => !value)
        ) {
            return;
        }

        const variables: IFacebookConversionEvent = { eventId, eventInputData, eventName };

        sendFacebookConversionEvent({ variables }).catch((error) => {
            if (process.env.NODE_ENV !== 'production') {
                console.error(error);
            }
        });
    }, [JSON.stringify(eventInputData), eventId, eventName, isTrackingEnabled, sendFacebookConversionEvent]);
};
