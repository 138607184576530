import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { mapPurchaseData } from '../utils';
import type { IPurchase } from '../types';
import { ConversionEventTypesEnum, PixelEventTypesEnum } from '../types';

export const usePurchase = (purchaseData: IPurchase): void => {
    const { orderNumber: orderIncrementId, ...restPurchaseData } = purchaseData || {};
    const eventId = useUuid([orderIncrementId]);

    useConversionApiRequest({
        eventId,
        eventInputData: { orderIncrementId },
        eventName: ConversionEventTypesEnum.PURCHASE,
    });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.PURCHASE,
        payload: mapPurchaseData(restPurchaseData),
    });
};
