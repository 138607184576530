import { useCart, useCurrency, useEmail } from '@vaimo/emarsys-tracking/src/talons';
import { usePush, useSearch } from '@vaimo/emarsys-tracking/src/hooks';

export const useSearchPage = ({ searchTerm }) => {
    useEmail();
    useCurrency();
    useCart();
    const { searchData } = useSearch({ searchTerm });
    usePush([searchData]);
};
