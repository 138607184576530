import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { useFacebookContext } from '../context/facebook';
import { ConversionEventTypesEnum, PixelEventTypesEnum, ISku } from '../types';

export const useAddToWishlist = ({ sku }: ISku): void => {
    const [{ lastViewedProduct }] = useFacebookContext();
    const eventId = useUuid([sku]);

    useConversionApiRequest({ eventId, eventInputData: { sku }, eventName: ConversionEventTypesEnum.ADD_TO_WISHLIST });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.ADD_TO_WISHLIST,
        payload: lastViewedProduct,
    });
};
