import type { ConfigurableProductOptions, ConfigurableCartItemPrices } from '../hooks/types';

export const findSizeIndex = (options: ConfigurableProductOptions[], label: string) =>
    options
        ?.find((option: any) => option.attribute_code === 'size')
        ?.values?.find((value) => value.store_label === label);

export const getProductIdFromSku = (sku: string): string => sku?.split('-').shift() || '';

export const getSizes = (
    options: {
        attribute_code: string;
        values: {
            isDisabled?: boolean;
            store_label: string;
        }[];
    }[],
) =>
    options
        .find((option) => option.attribute_code === 'size')
        ?.values.map(({ isDisabled, store_label }) => ({
            isAvailable: !isDisabled,
            value: store_label,
        })) || [];

export const getOrderConfirmationProductDataFromSku = (sku: string) => {
    const [productId, purchasedSize] = sku.split('-');

    return {
        id: sku,
        productId,
        purchasedSize,
    };
};

export const getPriceWithOrWithoutTax = (
    prices: ConfigurableCartItemPrices,
    quantity: number,
    isPriceIncludesTax: boolean,
) =>
    isPriceIncludesTax
        ? (prices.row_total_including_tax.value - prices.total_item_discount.value) / quantity
        : prices.final_price.value;
