import { useEffect } from 'react';

export const useScript = ({ isEnabled, src }: { isEnabled: boolean; src: string | null }) => {
    useEffect(() => {
        if (!src || !isEnabled || document.querySelector(`script[src="${src}"]`)) {
            return;
        }

        const script = Object.assign(document.createElement('script'), {
            async: true,
            src,
            type: 'text/javascript',
        });

        globalThis.document.head.appendChild(script);

        return () => {
            if (document.querySelector(`script[src="${src}"]`)) {
                globalThis.document.head.removeChild(script);
            }
        };
    }, [isEnabled, src]);
};
