import { useEffect, useMemo } from 'react';
import { useEmarsysContext } from '../context/emarsys';
import EmarsysTracker from '@vaimo/emarsys-tracking/src/helpers/emarsysTracker';
import { getPriceWithOrWithoutTax } from '@vaimo/emarsys-tracking/src/helpers/utils';

export const usePurchase = ({ orderDetailsData, orderNumber }) => {
    const [{ isPriceIncludesTax }] = useEmarsysContext();

    const purchaseData = useMemo(() => {
        if (!orderDetailsData?.cart?.items || !orderNumber) return;

        return {
            purchase: {
                items: orderDetailsData.cart.items.map((item) => ({
                    item: item.configured_variant?.sku || item.product?.sku,
                    price: getPriceWithOrWithoutTax(item, isPriceIncludesTax),
                    quantity: item.quantity,
                })),
                orderId: orderNumber,
            },
        };
    }, [isPriceIncludesTax, orderDetailsData, orderNumber]);

    useEffect(() => {
        if (!purchaseData) return;

        EmarsysTracker.data(purchaseData);
    }, [purchaseData]);

    return { purchaseData };
};
