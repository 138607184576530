import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { useFacebookContext } from '../context/facebook';
import { mapProDiscountAmountData } from '../utils';
import { ConversionEventTypesEnum, PixelEventTypesEnum } from '../types';

export const useProDiscountAmount = ({ isSignedIn }: { isSignedIn?: boolean }): void => {
    const [{ userProData }] = useFacebookContext();
    const eventId = useUuid([userProData?.customer_id]);

    const isActiveProUser = isSignedIn && userProData?.pro_account?.discount_applicable;

    useConversionApiRequest({
        eventId,
        eventInputData: { customerId: isActiveProUser ? userProData?.customer_id : null },
        eventName: ConversionEventTypesEnum.PRO_DISCOUNT_AMOUNT,
    });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.PRO_DISCOUNT_AMOUNT,
        payload: mapProDiscountAmountData({
            discountAmount: userProData?.pro_account?.discount,
            isActiveProUser,
        }),
    });
};
