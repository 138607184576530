import { gql } from '@apollo/client';

const FACEBOOK_CONVERSION_EVENT_MUTATION = gql`
    mutation facebookConversionEvent($eventId: String, $eventInputData: EventInputData!, $eventName: EventNameEnum!) {
        sendFacebookConversionRequest(
            input: { eventId: $eventId, eventInputData: $eventInputData, eventName: $eventName }
        ) {
            eventId
        }
    }
`;

const GET_FACEBOOK_PIXEL_USER_DATA = gql`
    query getFacebookPixelUserData {
        facebookPixelUserData {
            key
            value
        }

        customer {
            id
            customer_id
            pro_account {
                classification {
                    title
                    uid
                }
                discount
                discount_applicable
            }
        }
    }
`;

export default {
    facebookConversionEventMutation: FACEBOOK_CONVERSION_EVENT_MUTATION,
    getFacebookPixelUserDataQuery: GET_FACEBOOK_PIXEL_USER_DATA,
};
