import { convertNumberToFixedDecimals } from './convertNumberToFixedDecimals';
import type { ICheckoutItems, IPixelCheckoutEvent, IPixelAddPaymentInfoEvent } from '../types';
import { ContentCategoriesEnum } from '../types';

export const mapCheckoutData = (checkoutData: ICheckoutItems): IPixelCheckoutEvent => ({
    content_category: ContentCategoriesEnum.BEGIN_CHECKOUT,
    content_ids: (checkoutData?.items || []).map((item) => item.sku),
    contents: (checkoutData?.items || []).map((item) => ({
        id: item.sku,
        item_price: convertNumberToFixedDecimals(item.prices?.row_total?.value / (item.quantity || 1), 2),
        quantity: item.quantity,
    })),
    currency: checkoutData?.items[0]?.prices?.row_total?.currency,
    num_items: checkoutData?.quantity,
    value: convertNumberToFixedDecimals(
        (checkoutData?.items || []).reduce((total, item) => total + item.prices?.row_total?.value, 0),
        2,
    ),
});

export const mapAddPaymentInfoData = (checkoutData: ICheckoutItems): IPixelAddPaymentInfoEvent =>
    checkoutData?.paymentMethod
        ? {
              ...mapCheckoutData(checkoutData),
              payment_type: checkoutData?.paymentMethod,
          }
        : { payment_type: null };
