import { useEffect } from 'react';
import { useEmarsysContext } from '../context/emarsys';
import { getPriceWithOrWithoutTax } from '../helpers/utils';
import EmarsysTracker from '@vaimo/emarsys-tracking/src/helpers/emarsysTracker';

export const useCategoryPage = ({ categoryPath, pathname, searchTerm = '' }) => {
    const [
        { cartData, currency, currentUserEmail, isPriceIncludesTax, isWorkwearStore, previousPath },
        {
            actions: { setPreviousPath },
        },
    ] = useEmarsysContext();
    const path = categoryPath?.map(({ text }) => text).join(' > ');

    const items = (cartData?.cart?.items || [])?.map((item) => ({
        item: item.configured_variant?.sku || item.product?.sku,
        price: getPriceWithOrWithoutTax(item, isPriceIncludesTax),
        quantity: item.quantity,
    }));

    const collectedData = {
        cart: items,
        displayCurrency: currency?.current_currency_code
            ? currency.current_currency_code
            : currency?.default_display_currency_code,
        ...(!searchTerm && { category: path }),
        ...(currentUserEmail && { setEmail: currentUserEmail || null }),
        ...(searchTerm && { searchTerm }),
    };

    useEffect(() => {
        if (!collectedData || pathname === previousPath) return;

        EmarsysTracker.data(collectedData);
        EmarsysTracker.push(isWorkwearStore);

        setPreviousPath(pathname);
    }, [pathname]);
};
