import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { mapCheckoutData } from '../utils';
import type { ICheckout } from '../types';
import { ConversionEventTypesEnum, PixelEventTypesEnum } from '../types';

export const useInitiateCheckout = (checkoutData: ICheckout): void => {
    const { cartId, ...restCheckoutData } = checkoutData || {};
    const eventId = useUuid([cartId]);

    useConversionApiRequest({
        eventId,
        eventInputData: { maskedCartId: Boolean(restCheckoutData.quantity) && cartId },
        eventName: ConversionEventTypesEnum.INITIATE_CHECKOUT,
    });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.INITIATE_CHECKOUT,
        payload: Boolean(restCheckoutData.quantity) && mapCheckoutData(restCheckoutData),
    });
};
