import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { mapProSignupData } from '../utils';
import { PixelEventTypesEnum, IEmail, ConversionEventTypesEnum } from '../types';

export const useProSignupForm = ({ email }: IEmail): void => {
    const eventId = useUuid([email]);

    useConversionApiRequest({
        eventId,
        eventInputData: { email },
        eventName: ConversionEventTypesEnum.PRO_SIGNUP_FORM,
    });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.PRO_SIGNUP_FORM,
        payload: mapProSignupData(),
    });
};
