'use client';

import React, { ReactElement, ReactNode } from 'react';
import { FiX as CrossIcon } from 'react-icons/fi';
import { useTranslations } from 'next-intl';
import { use100vh } from 'react-div-100vh';
import { useScrollLock } from '@/hooks/useScrollLock';
import useIsMobile from '@/hooks/useIsMobile';

import Portal from '@/components/Portal';
import Mask from '@/components/Mask';

import clsx from 'clsx';
import classes from './modal.module.scss';

interface ModalProps {
    active: boolean;
    children?: ReactNode;
    className?: string;
    close: (e?: unknown) => void;
    disableFullWindowHeight?: boolean;
    isWishlist?: boolean;
    shouldCloseOnMaskClick?: boolean;
    title?: string;
}

const Modal = ({
    active,
    children,
    className,
    close,
    disableFullWindowHeight,
    isWishlist,
    shouldCloseOnMaskClick,
    title,
}: ModalProps): ReactElement => {
    const t = useTranslations('global');
    // @todo - consider replacing this with another solution, that doesn't rely on JS
    const fullWindowHeight = use100vh();
    const isMobile = useIsMobile();

    // Fixes background from being scrolled behind active modal
    useScrollLock(active);

    return (
        <Portal>
            <aside className={clsx(active ? classes.modalActive : classes.modal)}>
                <Mask isActive={active} dismiss={shouldCloseOnMaskClick ? close : undefined} />
                <div
                    className={clsx(className, classes.dialog)}
                    style={{ height: !isMobile || disableFullWindowHeight ? undefined : fullWindowHeight || undefined }}
                >
                    <header className={classes.header}>
                        {title && <h3 className={isWishlist ? classes.productTitle : classes.title}>{title}</h3>}
                        <button onClick={close} className={classes.closeButton} title={t('close')}>
                            <CrossIcon className={classes.closeIcon} />
                        </button>
                    </header>
                    <div className={classes.content}>{children}</div>
                </div>
            </aside>
        </Portal>
    );
};

Modal.displayName = 'Modal';

export default Modal;
