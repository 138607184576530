import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import actions from '@vaimo/emarsys-tracking/src/store/actions/emarsys';

export const useSetCartData = () => {
    const dispatch = useDispatch();

    const setCartData = useCallback(
        (cartData) => {
            if (!cartData) {
                return;
            }

            dispatch(actions.setCartData(cartData));
        },
        [dispatch],
    );

    return {
        setCartData,
    };
};
