'use client';

import React, { KeyboardEvent, ReactElement, useCallback, useRef, useState } from 'react';
import { useTranslations } from 'next-intl';
import { Formik, Form, type FormikProps } from 'formik';
import useIsMobile from '@/hooks/useIsMobile';
import { useScrollLock } from '@/hooks/useScrollLock';
import { useEventListener } from '@/hooks/useEventListener';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import ConfirmationDialog from './confirmationDialog';

import type { IDIalogProps } from './types';
import classes from './dialog.module.scss';

/**
 * The Dialog component shows its children content in a dialog,
 * encoding the look-and-feel and behavior in one place for consistency across the app.
 *
 */
const Dialog = (props: IDIalogProps): ReactElement => {
    const {
        additionalText,
        additionalTranslationId,
        cancelText = 'Cancel',
        cancelTranslationId = 'global.cancelButton',
        children,
        confirmIcon,
        confirmText = 'Confirm',
        confirmTranslationId = 'global.confirmButton',
        formProps,
        formikProps,
        hasAdditionalButton,
        hasCancelButton,
        isOpen,
        isWishlist,
        onAdditionalAction,
        onCancel,
        onConfirm,
        shouldCancelOnEsc,
        shouldCancelOnOutsideClick,
        shouldConfirmOnClose,
        shouldDisableAdditionalButton,
        shouldDisableAllButtons,
        shouldDisableConfirmButton,
        shouldShowButtons = true,
        shouldUnmountOnHide = true,
        title,
    } = props;

    const t = useTranslations();
    const formRef = useRef<FormikProps<Record<string, string>>>(null);

    const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState<boolean>(false);

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (shouldCancelOnEsc && event.key === 'Escape') {
                onCancel?.();
            }

            if (event.key === 'Enter' || event.key === '13') {
                event.preventDefault();
            }
        },
        [onCancel, shouldCancelOnEsc],
    );

    useEventListener(globalThis.document, 'keydown', handleKeyPress);

    const handleCloseClick = useCallback(
        (e: unknown) => {
            if (shouldConfirmOnClose && formRef?.current?.dirty) {
                setShouldShowConfirmationModal(true);
            } else {
                onCancel?.(e);
            }
        },
        [onCancel, shouldConfirmOnClose],
    );

    const handleConfirmationCancel = useCallback(
        (e: unknown) => {
            onCancel?.(e);
            setShouldShowConfirmationModal(false);
        },
        [onCancel],
    );

    const handleConfirmationApply = useCallback(() => {
        if (formProps) {
            formRef?.current?.submitForm();
        }
        setShouldShowConfirmationModal(false);
    }, [formProps]);

    useScrollLock(isOpen);
    const isMobile = useIsMobile();

    const maybeButtons = shouldShowButtons ? (
        <div className={classes.buttonsWrapper}>
            {hasCancelButton && (
                <Button
                    className={classes.button}
                    disabled={shouldDisableAllButtons}
                    onClick={onCancel}
                    variant={'secondary'}
                    type="reset"
                    isSmall={!isMobile}
                    isFullWidth={isMobile}
                    dataCustomId="dialog-cancel-button"
                >
                    {t(cancelTranslationId) || cancelText}
                </Button>
            )}
            <Button
                className={classes.button}
                disabled={shouldDisableAllButtons || shouldDisableConfirmButton}
                variant={'primary'}
                type="submit"
                onClick={!formProps ? onConfirm : undefined}
                isSmall={!isMobile}
                isFullWidth={isMobile}
                dataCustomId="dialog-submit-button"
            >
                {confirmIcon && confirmIcon}
                {t(confirmTranslationId) || confirmText}
            </Button>
            {hasAdditionalButton && (
                <Button
                    className={classes.button}
                    disabled={shouldDisableAllButtons || shouldDisableAdditionalButton}
                    variant={'tertiary'}
                    type="button"
                    isSmall={!isMobile}
                    isFullWidth={isMobile}
                    onClick={onAdditionalAction}
                    dataCustomId="dialog-additional-button"
                >
                    {t(additionalTranslationId) || additionalText}
                </Button>
            )}
        </div>
    ) : null;

    const modalContent = formProps ? (
        <div className={classes.maybeFormWrapper}>
            <Formik
                {...formikProps}
                initialValues={formProps.initialValues || {}}
                validationSchema={formProps.validationSchema}
                onSubmit={onConfirm!}
                innerRef={formRef}
            >
                <Form>
                    {/* The Dialog. */}
                    {children}
                    {maybeButtons}
                </Form>
            </Formik>
        </div>
    ) : (
        <div className={classes.contentWrapper}>
            {/* The Dialog. */}
            {children}
            {maybeButtons}
        </div>
    );

    return (
        <>
            <Modal
                active={isOpen}
                title={title}
                close={handleCloseClick}
                shouldCloseOnMaskClick={shouldCancelOnOutsideClick}
                isWishlist={isWishlist}
            >
                {(isOpen || !shouldUnmountOnHide) && modalContent}
            </Modal>
            {shouldConfirmOnClose && (
                <ConfirmationDialog
                    isMobile={isMobile}
                    isOpen={shouldShowConfirmationModal}
                    onCancel={handleConfirmationCancel}
                    onConfirm={handleConfirmationApply}
                    shouldDisableConfirmButton={shouldDisableConfirmButton}
                />
            )}
        </>
    );
};

Dialog.displayName = 'Dialog';

export default Dialog;
