import { useEffect, useMemo } from 'react';
import EmarsysTracker from '@vaimo/emarsys-tracking/src/helpers/emarsysTracker';

export const useSearch = ({ searchTerm }) => {
    const searchData = useMemo(() => {
        if (!searchTerm) return;

        return { searchTerm };
    }, [searchTerm]);

    useEffect(() => {
        if (!searchData) return;

        EmarsysTracker.data(searchData);
    }, [searchData]);

    return { searchData };
};
