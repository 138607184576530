import React, { createContext, ReactNode, useContext } from 'react';
import { BoldMetricsFields } from '@/components/AppIntegrations/types';

interface BoldMetricsContext extends BoldMetricsFields {}

const BoldMetricsContext = createContext({} as BoldMetricsContext);

const BoldMetricsContextProvider = ({
    children,
    initState,
}: {
    children: ReactNode;
    initState: BoldMetricsContext;
}) => {
    return <BoldMetricsContext.Provider value={initState}>{children}</BoldMetricsContext.Provider>;
};

export default BoldMetricsContextProvider;

export const useBoldMetricsContext = () => useContext(BoldMetricsContext);
