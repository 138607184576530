import { useEffect } from 'react';
import { usePixelData } from './usePixelData';
import { PixelTrackTypesEnum, type IPixelEvent } from '../types';
import { useFacebookContext } from '../context/facebook';

export const usePixelTrackEvent = ({ eventId, eventName, payload }: IPixelEvent): void => {
    const [{ isPixelEnabled }] = useFacebookContext();
    const pixelData = usePixelData();

    useEffect(() => {
        if (
            !isPixelEnabled ||
            !eventId ||
            !eventName ||
            !globalThis.fbq ||
            !payload ||
            !Object.values(payload).every(Boolean)
        ) {
            return;
        }

        globalThis.fbq(PixelTrackTypesEnum.TRACK, eventName, { ...payload, ...pixelData }, { eventID: eventId });
    }, [eventId, eventName, isPixelEnabled, JSON.stringify(payload), JSON.stringify(pixelData)]);
};
