import { useEffect, useState } from 'react';
import { useEmarsysContext } from '../context/emarsys';
import EmarsysTracker from '@vaimo/emarsys-tracking/src/helpers/emarsysTracker';
import usePrevious from '@vaimo/emarsys-tracking/src/hooks/usePrevious';
import { getPriceWithOrWithoutTax } from '@vaimo/emarsys-tracking/src/helpers/utils';

const getAddedProduct = (prevCart, currentCart) => {
    if (!prevCart || !currentCart) {
        return null;
    }

    const prevItems = prevCart.cart.items;
    const currentItems = currentCart.cart.items;

    for (let currentItem of currentItems) {
        const prevItem = prevItems.find((prevItem) => prevItem.uid === currentItem.uid);

        if (!prevItem) {
            return currentItem;
        }

        if (prevItem.quantity < currentItem.quantity) {
            return {
                ...currentItem,
                quantity: currentItem.quantity - prevItem.quantity,
            };
        }
    }
};

export const useAddToCart = () => {
    const [{ cartData: cartContent, isPriceIncludesTax, isWorkwearStore }] = useEmarsysContext();
    const cartId = cartContent?.cart?.id;

    const [currentCart, setCurrentCart] = useState(null);
    const [addedToCartProduct, setAddedToCartProduct] = useState(null);
    const prevCartId = usePrevious(cartId);
    const previousCart = usePrevious(currentCart);
    const [isMergingCart, setIsMergingCart] = useState(false);

    useEffect(() => {
        if (!prevCartId) return;

        if (!cartId) {
            if (!isMergingCart) {
                setIsMergingCart(true);
            }
        } else {
            if (isMergingCart && cartId === prevCartId) {
                setIsMergingCart(false);
            }
        }
    }, [prevCartId, cartId, isMergingCart]);

    useEffect(() => {
        if (!cartContent) return;

        setCurrentCart(cartContent);
    }, [cartContent]);

    useEffect(() => {
        if (isMergingCart || (currentCart?.cart && previousCart?.cart?.id !== currentCart.cart.id)) {
            return;
        }

        const addedProduct = getAddedProduct(previousCart, currentCart);

        if (addedProduct) {
            setAddedToCartProduct(addedProduct);
        }
    }, [previousCart, currentCart, isMergingCart]);

    useEffect(() => {
        if (addedToCartProduct) {
            EmarsysTracker.data({
                cart: currentCart?.cart?.items?.map((item) => ({
                    item: item.configured_variant?.sku || item.product?.sku,
                    price: getPriceWithOrWithoutTax(item, isPriceIncludesTax),
                    quantity: item.quantity,
                })),
            });

            EmarsysTracker.push(isWorkwearStore);
        }
    }, [addedToCartProduct, isPriceIncludesTax]);
};
