import type { ICartProductContents, IProduct, IPixelAddToCartEvent } from '../types';
import { ContentTypesEnum } from '../types';
import { DEFAULT_QUANTITY } from './mapProductData';

const mapContents = (product: IProduct | null): ICartProductContents[] => [
    {
        id: product?.sku,
        item_price: (product?.value || 0) / (product?.quantity || DEFAULT_QUANTITY),
        quantity: product?.quantity,
    },
];

export const mapAddToCartData = (product: IProduct | null): IPixelAddToCartEvent => ({
    content_ids: [product?.sku],
    content_name: product?.name,
    content_type: ContentTypesEnum.PRODUCT,
    contents: mapContents(product),
    currency: product?.currency,
    value: product?.value,
});
