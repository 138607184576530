import { useEffect } from 'react';
import EmarsysTracker from '@vaimo/emarsys-tracking/src/helpers/emarsysTracker';

export const useEmail = ({ email }) => {
    useEffect(() => {
        if (!email) return;

        EmarsysTracker.data({
            setEmail: email,
        });
    }, []);
};
