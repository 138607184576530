import { useQuery } from '@apollo/client';
import { getCookie } from '../utils/getCookie';
import { getCustomerIdQuery } from '../api/fitAnalytics.gql';
import type { FitAnalytics, IFitAnalyticsProps, FitAnalyticsCustomerIdResponse } from './types';

export const GOOGLE_ANALYTICS_USER_ID = '_ga';

export const useFitAnalytics = ({ currencyCode, isSignedIn, storeCode }: IFitAnalyticsProps): FitAnalytics => {
    const { data } = useQuery<FitAnalyticsCustomerIdResponse>(getCustomerIdQuery, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !isSignedIn,
    });

    const SSID = getCookie(GOOGLE_ANALYTICS_USER_ID) || '';
    const [shopLanguage, shopCountry] = (storeCode || '').split('_');

    return {
        SSID,
        currency: currencyCode || '',
        shopCountry: shopCountry?.toUpperCase(),
        shopLanguage,
        userId: data?.customer?.customer_id ?? null,
    };
};
