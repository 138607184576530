import { cookieManager } from '@vaimo-int/one-trust';

export default {
    data: (data) => {
        const entries = data && typeof data === 'object' ? Object.entries(data) : [];

        if (entries.length && globalThis.ScarabQueue) {
            entries.forEach((entry) => globalThis.ScarabQueue.push(entry));
        }
    },
    initialize: (merchantId) => {
        cookieManager.executeIfAllowed(() => {
            if (merchantId && typeof merchantId === 'string') {
                globalThis.ScarabQueue = globalThis.ScarabQueue || [];
                (function (id) {
                    if (globalThis.document.getElementById(id)) return;
                    const js = globalThis.document.createElement('script');
                    js.id = id;
                    js.src = merchantId;
                    js.async = true;
                    const fs = globalThis.document.querySelector('script');
                    fs.parentNode.insertBefore(js, fs);
                })('scarab-js-api');
            }
        }, cookieManager.PrivacyGroupEnum.PERFORMANCE);
    },
    push: (isWorkwearStore) => {
        if (!globalThis.ScarabQueue) {
            return;
        }

        if (isWorkwearStore) {
            ScarabQueue.push([
                'tag',
                'custom_source',
                {
                    source: 'WorkWear',
                },
            ]);
        }

        globalThis.ScarabQueue.push(['go']);
    },
};
