import type { IProduct, IPixelProductEvent, ProductCategory } from '../types';
import { ContentTypesEnum } from '../types';

const CATEGORY_INTERNAL_DELIMITER = ' | ';
const CATEGORIES_DELIMITER = ' || ';

const CONTENT_TYPE = ContentTypesEnum.PRODUCT;
export const DEFAULT_QUANTITY = 1;
const DEFAULT_PRICE = 0;

enum ProductPropertiesEnum {
    SKU = 'sku',
}

interface ICategories {
    categories?: ProductCategory[];
}

const mapProductsProperty = (products: IProduct[], propertyName: string, defaultValue?: any): string[] =>
    products?.map((product) => {
        const property = (product as Record<string, any>)[propertyName];

        return typeof property === 'string' ? property || defaultValue : (property || defaultValue)?.toString();
    });

const mapProductCategories = ({ categories }: ICategories): string[] =>
    categories?.map((category) =>
        [...(category?.breadcrumbs?.map(({ category_name }) => category_name) || []), category?.name].join(
            CATEGORY_INTERNAL_DELIMITER,
        ),
    ) || [];

export const mapProductData = (product: IProduct): IPixelProductEvent => ({
    content_category: mapProductCategories(product).join(CATEGORIES_DELIMITER),
    content_ids: mapProductsProperty([product], ProductPropertiesEnum.SKU),
    content_name: product.name,
    content_type: CONTENT_TYPE,
    contents: [{ id: product.sku, item_price: product.value, quantity: DEFAULT_QUANTITY }],
    currency: product.currency,
    quantity: DEFAULT_QUANTITY,
    value: product.value || DEFAULT_PRICE,
});
