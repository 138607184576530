import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { mapPageTypeData } from '../utils';
import { PixelEventTypesEnum, IPageType, ConversionEventTypesEnum } from '../types';

export const usePageType = ({ pageType }: IPageType): void => {
    const eventId = useUuid([pageType]);

    useConversionApiRequest({
        eventId,
        eventInputData: { pageType },
        eventName: ConversionEventTypesEnum.PAGE_TYPE_EVENT,
    });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.PAGE_TYPE_EVENT,
        payload: mapPageTypeData({ pageType }),
    });
};
