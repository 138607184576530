import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { mapPageViewData } from '../utils';
import { ConversionEventTypesEnum, PixelEventTypesEnum } from '../types';

export const usePageView = ({ pageUrl }: { pageUrl: string }): void => {
    const eventId = useUuid([pageUrl]);

    useConversionApiRequest({ eventId, eventInputData: { pageUrl }, eventName: ConversionEventTypesEnum.PAGE_VIEW });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.PAGE_VIEW,
        payload: mapPageViewData({ pageUrl }),
    });
};
