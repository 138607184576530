import { useCart, useCurrency, useEmail } from '@vaimo/emarsys-tracking/src/talons';
import { useProduct, usePush } from '@vaimo/emarsys-tracking/src/hooks';

export const useProductPage = ({ product }) => {
    useEmail();
    useCurrency();
    useCart();
    useProduct({ product });
    usePush();
};
