import { useEffect, useState } from 'react';
import { BoldMetricsFields } from '@/components/AppIntegrations/types';
import { cookieManager } from '@vaimo-int/one-trust';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare module globalThis {
    let _boldmetrics: any;
    let document: any;
}

export const useBoldMetricsScriptProductPage = ({
    boldMetricsScriptPath,
    isBoldMetricsEnabled,
}: BoldMetricsFields): void => {
    const [scriptInit, setScriptInit] = useState(false);

    useEffect(() => {
        if (!isBoldMetricsEnabled || !boldMetricsScriptPath) return;
        if (globalThis?._boldmetrics?.product && !scriptInit) {
            cookieManager.insertScript({
                async: true,
                groupId: cookieManager.PrivacyGroupEnum.FUNCTIONAL,
                url: boldMetricsScriptPath,
            });
            setScriptInit(true);
        }
    }, [boldMetricsScriptPath, isBoldMetricsEnabled, globalThis?._boldmetrics]);
};
