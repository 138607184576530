import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

type Dependencies = Array<string | number | undefined>;

export const useUuid = (deps: Dependencies): string | undefined =>
    useMemo(() => {
        if (!deps || deps.some((dep) => !dep)) {
            return;
        }

        return uuidv4();
    }, deps);
