import { useEffect } from 'react';
import { useEmarsysContext } from '../context/emarsys';
import EmarsysTracker from '@vaimo/emarsys-tracking/src/helpers/emarsysTracker';

export const usePush = (deps = []) => {
    const [{ isWorkwearStore }] = useEmarsysContext();

    useEffect(() => {
        if (deps.length === 1 && !deps[0]) {
            // Protecting from async actions, when structure data is loaded later
            return;
        }
        EmarsysTracker.push(isWorkwearStore);
    }, deps);
};
