import React, { ReactElement } from 'react';
import LogoSportswear from './assets/logo-sportswear';
import LogoWorkwear from './assets/logo-workwear';
import classes from './logo.module.scss';

const Logo = ({
    isProgressBar,
    isSportswearTheme,
    label,
}: {
    isProgressBar?: boolean;
    isSportswearTheme?: boolean;
    label?: string;
}): ReactElement => (
    <div className={classes.root}>
        {isSportswearTheme ? (
            <LogoWorkwear className={classes.logo} width={118} height={70} />
        ) : (
            <LogoSportswear className={classes.logo} width={118} height={70} />
        )}
        {isProgressBar && <div className={classes.progressBar}></div>}
        {!!label && <div>{label}</div>}
    </div>
);

export default Logo;
