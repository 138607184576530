import React, { ReactElement, ReactNode } from 'react';

import Spinner from '@/components/Spinner';

import mergeClasses from '@/utils/Object/shallowMerge';
import clsx from 'clsx';
import defaultClasses from './indicator.module.scss';

interface ILoadingIndicatorProps {
    children?: ReactNode;
    classes?: {
        [className: string]: string;
    };
    isFullWidth?: boolean;
}

const LoadingIndicator = ({
    children,
    classes: propClasses,
    isFullWidth = false,
}: ILoadingIndicatorProps): ReactElement => {
    const classes = mergeClasses(defaultClasses, propClasses);

    const loaderWrapperClasses = clsx(classes.loaderWrapper, isFullWidth && classes.loaderWrapperFull);

    return (
        <div className={classes.indicatorWrapper}>
            <div className={loaderWrapperClasses}>
                <Spinner />
            </div>
            {children && <span className={classes.message}>{children}</span>}
        </div>
    );
};

LoadingIndicator.displayName = 'LoadingIndicator';

export default LoadingIndicator;
