import { useEffect } from 'react';
import EmarsysTracker from '@vaimo/emarsys-tracking/src/helpers/emarsysTracker';

export const useProduct = ({ product }) => {
    useEffect(() => {
        if (!product?.sku) return;

        EmarsysTracker.data({
            view: `g/${product.sku}`,
        });
    }, []);
};
