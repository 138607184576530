import { useEffect } from 'react';
import { cookieManager } from '@vaimo-int/one-trust';
import { useFitAnalytics } from './useFitAnalytics';
import { findSizeIndex, getProductIdFromSku, getSizes } from '../utils/utils';
import { useMutation } from '@apollo/client';
import { useScript } from './useScript';
import type { UseFitAnalyticsProductProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare module globalThis {
    let fitAnalyticsData: any;
}

export const useFitAnalyticsProduct = ({
    addToCartMutation,
    cartId,
    currencyCode,
    isEnabled,
    isSignedIn,
    matchingItem,
    product,
    productOptions,
    scriptSrc,
    storeCode,
    thumbnail,
    variants,
}: UseFitAnalyticsProductProps): void => {
    const { SSID, shopCountry, shopLanguage, userId } = useFitAnalytics({ currencyCode, isSignedIn, storeCode });
    const allProductIds = [...new Set(variants.map(({ product }) => getProductIdFromSku(product.sku)))] as string[];
    const [addConfigurableProductToCart] = useMutation(addToCartMutation);

    useScript({ isEnabled, src: scriptSrc });

    useEffect(() => {
        if (!isEnabled || !product || !scriptSrc) {
            return;
        }

        cookieManager.executeIfAllowed(() => {
            globalThis.fitAnalyticsData = {
                SSID,
                allProductIds,
                currentProductId: getProductIdFromSku(matchingItem?.product.sku),
                mainThumbnail: thumbnail,
                operations: {
                    addToCart: (size: string, productId: string | number, quantity: number = 1) => {
                        const { value_index } = findSizeIndex(productOptions, size) || {};
                        const matchingVariant = variants.find(
                            (variant) =>
                                variant.attributes.some((attribute) => attribute.value_index === value_index) &&
                                getProductIdFromSku(variant.product.sku) === productId,
                        );

                        if (!matchingVariant) return;

                        const { sku } = matchingVariant.product;

                        addConfigurableProductToCart({
                            variables: {
                                cartId,
                                parentSku: product.sku,
                                quantity,
                                sku,
                            },
                        });
                    },
                    getSizeChartLink: () => document.querySelector('[data-fit-finder]'),
                },
                shopCountry,
                shopLanguage,
                sizes: getSizes(productOptions),
                userId,
            };
        }, cookieManager.PrivacyGroupEnum.FUNCTIONAL);

        return () => {
            delete globalThis.fitAnalyticsData;
        };
    }, [
        SSID,
        addConfigurableProductToCart,
        allProductIds,
        cartId,
        isEnabled,
        matchingItem,
        thumbnail,
        product,
        productOptions,
        shopCountry,
        shopLanguage,
        userId,
        variants,
    ]);
};
