import { convertNumberToFixedDecimals } from './convertNumberToFixedDecimals';
import type { IPixelPurchaseEvent, IPurchaseItems } from '../types';
import { ContentNamesEnum, ContentTypesEnum } from '../types';

export const mapPurchaseData = (purchaseData: IPurchaseItems): IPixelPurchaseEvent => ({
    content_ids: (purchaseData?.items || []).map((item) => item.sku),
    content_name: ContentNamesEnum.PURCHASE,
    content_type: ContentTypesEnum.PRODUCT,
    contents: (purchaseData?.items || []).map((item) => ({
        id: item.sku,
        item_price: convertNumberToFixedDecimals(item.prices?.row_total?.value / (item.quantity || 1), 2),
        quantity: item.quantity,
    })),
    currency: purchaseData?.items[0]?.prices?.row_total?.currency,
    value: convertNumberToFixedDecimals(
        (purchaseData?.items || []).reduce((total, item) => total + item.prices?.row_total?.value, 0),
        2,
    ),
});
