import { useEffect, useState } from 'react';
import { useLocale } from 'next-intl';
import { IBoldMetricsObject } from '@vaimo/bold-metrics/src/types';

function findProductBySizeValueIndex(productSwatches: any, sizeValueIndex: number) {
    for (const variant of productSwatches.variants) {
        for (const attribute of variant.attributes) {
            if (attribute.code === 'size' && attribute.value_index === sizeValueIndex) {
                return variant.product || {};
            }
        }
    }

    return {};
}

const isNumeric = (str: string): boolean => !isNaN(Number(str)) && !isNaN(parseFloat(str));

export const useBoldMetricsObject = ({
    isBoldMetricsEnabled,
    product,
    productOptions,
    productSwatches,
}: IBoldMetricsObject) => {
    const locale = useLocale();
    const { description, hh_product_type, id, name, price_range, sizechart } = product;
    const isAvailable = !!(sizechart && hh_product_type);
    const sizeOptions = productOptions?.find((option: any) => option.attribute_code === 'size');

    const [selectedVariants, setVariants] = useState([]);

    useEffect(() => {
        if (sizeOptions) {
            const newVariants = sizeOptions.values.map((variant: any) => {
                const optionValueIndex = variant.value_index;
                const currentProductsSwatch = findProductBySizeValueIndex(productSwatches, optionValueIndex);

                return {
                    additionalMetadata: {},
                    available: !variant.isDisabled,
                    description: '',
                    fit: '',
                    id: currentProductsSwatch?.id,
                    size: variant.store_label,
                    sizechart: '',
                    sku: currentProductsSwatch?.sku,
                };
            });

            setVariants(newVariants as any);
        }
    }, []);

    useEffect(() => {
        if (!isBoldMetricsEnabled) return;

        if (isAvailable && selectedVariants.length > 0) {
            /* eslint-disable */
            // @ts-ignore
            const primaryDimension = isNumeric(selectedVariants[0].size) ? 'numeric' : 'alpha';
            const sizeChartData = `${sizechart}_${primaryDimension}`;
            /* eslint-disable */
            // @ts-ignore
            globalThis._boldmetrics = {
                locale,
                product: {
                    description,
                    id,
                    price: price_range.maxPrice,
                    primaryDimension,
                    secondaryDimensions: [],
                    sizechart: sizeChartData,
                    tags: [],
                    title: name,
                    type: hh_product_type,
                    variants: selectedVariants,
                },
            };

            return () => {
                /* eslint-disable */
                // @ts-ignore
                delete globalThis._boldmetrics;
            };
        }
    }, [locale, description, id, name, isAvailable, price_range, selectedVariants, isBoldMetricsEnabled, sizeOptions]);
};
