import { useEffect, useState } from 'react';
import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { mapProductData } from '../utils';
import { useFacebookContext } from '../context/facebook';
import type { IPixelProductEvent, IProduct } from '../types';
import { ConversionEventTypesEnum, PixelEventTypesEnum } from '../types';

export const useViewContent = (product: IProduct): void => {
    const [currentProduct, setCurrentProduct] = useState<IPixelProductEvent>();

    const { sku } = product || {};
    const eventId = useUuid([sku]);
    const [, { setLastViewedProduct }] = useFacebookContext();

    useEffect(() => {
        const mappedProduct = mapProductData(product);
        setCurrentProduct(mappedProduct);
        setLastViewedProduct(mappedProduct);
    }, [product, setLastViewedProduct]);

    useConversionApiRequest({ eventId, eventInputData: { sku }, eventName: ConversionEventTypesEnum.VIEW_CONTENT });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.VIEW_CONTENT,
        payload: currentProduct!,
    });
};
