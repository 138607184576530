import { useEffect } from 'react';
import { usePixelData } from '../talons/usePixelData';
import { arrayToObject } from '../utils/arrayToObject';
import { useLazyQuery } from '@apollo/client';
import operations from '../talons/conversionApi.gql';
import { useFacebookContext } from '../context/facebook';
import { cookieManager } from '@vaimo-int/one-trust';

const PARTYTOWN_SCRIPT_HANDLE = 'partytown';

export interface IUseAppendPixelScript {
    isSignedIn?: boolean;
}

export const useAppendPixelScript = ({ isSignedIn }: IUseAppendPixelScript): void => {
    const { pixelId } = usePixelData();
    const [
        {
            isPixelAllowDuplicatePageViews,
            isPixelEnabled,
            isPixelInitEnabled,
            isPixelPushStateDisabled,
            partytownFacebookEnabled,
        },
        { setUserProData },
    ] = useFacebookContext();
    const { getFacebookPixelUserDataQuery } = operations;

    // lazy query to FacebookPixelUserData
    const [runQuery, { data: userData }] = useLazyQuery(getFacebookPixelUserDataQuery, {
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if ((!isPixelEnabled && !isPixelInitEnabled) || !pixelId) {
            return;
        }

        //Getting Facebook user data after logging in without page reload
        if (isSignedIn && !userData) {
            runQuery();

            return;
        }

        setUserProData(userData?.customer);

        const facebookPixelUserData = userData?.facebookPixelUserData;

        const fbUserData =
            facebookPixelUserData?.length && isSignedIn ? JSON.stringify(arrayToObject(facebookPixelUserData)) : '';

        if (fbUserData) {
            cookieManager.executeIfAllowed(() => {
                sessionStorage.setItem('fb_user_data', fbUserData);
            }, cookieManager.PrivacyGroupEnum.TARGETING);
        } else {
            if (sessionStorage.getItem('fb_user_data')) {
                sessionStorage.removeItem('fb_user_data');
            }
        }

        const facebookPixelScript = Object.assign(document.createElement('script'), {
            defer: true,
            innerHTML: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${pixelId}', ${fbUserData});
                fbq.disablePushState = ${isPixelPushStateDisabled};
                fbq.allowDuplicatePageViews = ${isPixelAllowDuplicatePageViews};`,
            type: `text/${partytownFacebookEnabled ? PARTYTOWN_SCRIPT_HANDLE : 'javascript'}`,
        });

        cookieManager.executeIfAllowed(() => {
            globalThis.document.head.appendChild(facebookPixelScript);
        }, cookieManager.PrivacyGroupEnum.TARGETING);

        return () => {
            if (globalThis.document.head.contains(facebookPixelScript)) {
                globalThis.document.head.removeChild(facebookPixelScript);
            }
        };
    }, [
        partytownFacebookEnabled,
        isPixelEnabled,
        isPixelInitEnabled,
        isPixelPushStateDisabled,
        isPixelAllowDuplicatePageViews,
        pixelId,
        isSignedIn,
        userData?.facebookPixelUserData,
    ]);
};
