import { useEffect } from 'react';
import { useEmarsysContext } from '../context/emarsys';
import EmarsysTracker from '@vaimo/emarsys-tracking/src/helpers/emarsysTracker';

export const useInitialize = ({ currentUserEmail }) => {
    const [
        { emarsysScript },
        {
            actions: { setCurrentUserEmail },
        },
    ] = useEmarsysContext();

    useEffect(() => {
        setCurrentUserEmail(currentUserEmail);
    }, [currentUserEmail]);

    useEffect(() => {
        if (!emarsysScript) {
            return;
        }

        EmarsysTracker.initialize(emarsysScript);
    }, [emarsysScript]);
};
