import { useConversionApiRequest, usePixelTrackEvent } from '../talons';
import { useUuid } from './useUuid';
import { mapAddPaymentInfoData } from '../utils';
import type { IAddPaymentInfo } from '../types';
import { ConversionEventTypesEnum, PixelEventTypesEnum } from '../types';

export const useAddPaymentInfo = (checkoutData: IAddPaymentInfo): void => {
    const { cartId, ...restCheckoutData } = checkoutData || {};
    const eventId = useUuid([cartId]);

    useConversionApiRequest({
        eventId,
        eventInputData: { maskedCartId: Boolean(restCheckoutData.quantity) && cartId },
        eventName: ConversionEventTypesEnum.ADD_PAYMENT_INFO,
    });
    usePixelTrackEvent({
        eventId,
        eventName: PixelEventTypesEnum.ADD_PAYMENT_INFO,
        payload: Boolean(restCheckoutData.quantity) && mapAddPaymentInfoData(restCheckoutData),
    });
};
