import React from 'react';
import { useTranslations } from 'next-intl';
import Button from '@/components/Button';
import Modal from '@/components/Modal';

import type { IConfirmationDialogProps } from './types';
import classes from './confirmationDialog.module.scss';

const ConfirmationDialog = ({
    isMobile,
    isOpen,
    onCancel,
    onConfirm,
    shouldCancelOnOutsideClick,
    shouldDisableConfirmButton,
}: IConfirmationDialogProps) => {
    const t = useTranslations('confirmationDialog');

    return (
        <Modal
            active={isOpen}
            className={classes.confirmationDialog}
            close={onCancel}
            disableFullWindowHeight
            shouldCloseOnMaskClick={shouldCancelOnOutsideClick}
            title={t('title')}
        >
            <div className={classes.contentWrapper}>
                {t('message')}
                <div className={classes.buttonsWrapper}>
                    <Button
                        className={classes.button}
                        onClick={onCancel}
                        variant={'secondary'}
                        type="reset"
                        isSmall={!isMobile}
                        isFullWidth={isMobile}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        className={classes.button}
                        disabled={shouldDisableConfirmButton}
                        variant={'primary'}
                        type="submit"
                        onClick={onConfirm}
                        isSmall={!isMobile}
                        isFullWidth={isMobile}
                    >
                        {t('submit')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

ConfirmationDialog.displayName = 'ConfirmationDialog';

export default ConfirmationDialog;
