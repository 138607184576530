import { useEffect, useMemo } from 'react';
import EmarsysTracker from '@vaimo/emarsys-tracking/src/helpers/emarsysTracker';

export const useCategory = ({ categoryPath }) => {
    const categoryData = useMemo(() => {
        if (!categoryPath) return;

        return { category: categoryPath };
    }, [categoryPath]);

    useEffect(() => {
        if (!categoryData) return;

        EmarsysTracker.data(categoryData);
    }, [categoryData]);

    return { categoryData };
};
